import React, { FC } from "react"

const BREAKPOINT_PIXEL_SIZE = 600

interface IProps {
    desktopSrc: string
    mobileSrc?: string
    posterImage: string
    captionsFile?: string
}

const ResponsiveVideo: FC<IProps> = ({ desktopSrc, mobileSrc, posterImage, captionsFile }) => {
    const viewportWidth = typeof window !== "undefined" ? window.innerWidth : BREAKPOINT_PIXEL_SIZE

    const videoSource = mobileSrc && viewportWidth < BREAKPOINT_PIXEL_SIZE ? mobileSrc : desktopSrc

    return (
        <video
            src={videoSource}
            controls
            controlsList="nodownload"
            disablePictureInPicture
            style={{ width: "100%", height: "auto", borderRadius: 4 }}
            poster={posterImage}
            crossOrigin="anonymous"
        >
            <source src={videoSource} type="video/mp4" />
            <track src={captionsFile} kind="captions" srcLang="en" label="English" />
            Sorry, your browser doesn't support embedded videos.
        </video>
    )
}

export default ResponsiveVideo
